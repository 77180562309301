import * as React from "react";
import { HeadProps, PageProps, graphql } from "gatsby";

import { BuilderComponent } from "@builder.io/react";
import { getBuilderFaqs } from "@utils/getBuilderFaqs";
import { handleContentLoaded } from "@utils/builderContentLoaded";
import { RegisterComponentLibrary } from "@utils/RegisterComponentLibrary";
import FAQSchema from "@components/FAQSchema";
import MainLayout from "@layouts/main";
import SEOMeta from "@components/SEOMeta";
import withCalculatorProvider from "@components/HOC/withCalculatorProvider";

import "@builder.io/widgets";
import { enableSmoothScroll } from "@utils/enableSmoothScroll";

import "../../libs/builder.setting";
import { RegisterHomepageLegacy } from "@components/BuilderWrapperComponents/HomepageLegacy";
import { Cookie } from "@utils/Cookie";
import { usePageModelData } from "../../hooks/usePageModelData";

type DataProps = {
  allBuilderModels: Queries.builder;
};

const StaticPageFullNav: React.FC<PageProps<DataProps>> = ({ location }) => {
  const content = usePageModelData<
    Queries.builder_StaticPageFullNav["content"]
  >({
    model: "static-page-full-nav",
    urlPath: location.pathname,
    userAttr: {
      customer: Boolean(Cookie.getCookie("sp_last_access")),
    },
  });
  // const [content, setContent] = useState<Queries.builder_StaticPageFullNav["content"] | null>(null);
  // const device = useDevice();

  // const getContent = useCallback(() => {
  //   const isCohort = Cookie.checkCookie("RedesignCohort")
  //     ? parseInt(Cookie.getCookie("RedesignCohort") as string) === 2
  //       ? true
  //       : false
  //     : false;
  //   builder
  //     .get("static-page-full-nav", {
  //       userAttributes: {
  //         urlPath: location.pathname,
  //         device,
  //         customer: Boolean(Cookie.getCookie("sp_last_access")),
  //         redesignCohort: isCohort,
  //       },
  //       options: {
  //         cachebust: true,
  //         noTraverse: false,
  //         includeRefs: true,
  //       },
  //     })
  //     .toPromise()
  //     .then(res => {
  //       if (!res) navigate("/404/");

  //       setContent(res);
  //     });
  // }, [location.pathname, device]);

  // useEffect(() => {
  //   if (
  //     sessionStorage.getItem("pageCount") === "0" &&
  //     window.location.pathname.includes("/pricing")
  //   ) {
  //     if (!Cookie.checkCookie("RedesignCohort")) {
  //       const cohort = Math.random() > 0.5 ? 1 : 2;
  //       Cookie.setCookie("RedesignCohort", cohort.toString(), 365);
  //       let intervalCounter = 0;
  //       const myInterval = setInterval(() => {
  //         if (Cookie.checkCookie("RedesignCohort")) {
  //           getContent();
  //           clearInterval(myInterval);
  //         }
  //         intervalCounter++;
  //         if (intervalCounter >= 20) {
  //           getContent();
  //           clearInterval(myInterval);
  //         }
  //       }, 10);
  //     } else {
  //       getContent();
  //     }
  //   } else {
  //     getContent();
  //   }
  // }, [getContent]);

  const isScrollable = content?.data?.useScroll;
  enableSmoothScroll(isScrollable);

  return (
    <MainLayout preventRerender pathname={location.pathname}>
      {content && (
        <BuilderComponent
          content={content}
          contentLoaded={handleContentLoaded}
          model="static-page-full-nav"
        />
      )}
    </MainLayout>
  );
};

RegisterComponentLibrary();
RegisterHomepageLegacy();

export default withCalculatorProvider(StaticPageFullNav);

export const Head: React.FC<HeadProps<DataProps>> = ({ data }) => {
  const {
    allBuilderModels: { oneStaticPageFullNav },
  } = data;
  const seoData = oneStaticPageFullNav?.content
    ?.data as Queries.builder_PageFullNavData;

  const content = oneStaticPageFullNav?.content;
  const faqs = getBuilderFaqs(content);

  return (
    <>
      <SEOMeta
        index={seoData?.index!}
        title={seoData?.title!}
        description={seoData?.description!}
      />

      {faqs && <FAQSchema faqsArray={faqs} />}

      {seoData?.json && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              ...seoData.json,
            }),
          }}
        />
      )}
    </>
  );
};

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      oneStaticPageFullNav(
        target: { urlPath: $path }
        options: { cachebust: true, noTraverse: false, includeRefs: true }
      ) {
        content
      }
    }
  }
`;
